<template>
  <div class="Resume">
    <div @click="$router.push('/recruit/show?id='+item.jobSeekerId+'&ckzt='+(ckzt?ckzt:''))" class="item" v-for="item in dataList" :key="item.id">
      <img :src="item.headImgUrl || '/head-portrait2.png'" alt="">
      <div class="text">
        <h4>{{item.name}}</h4>
        <p>{{item.genderName}} | {{item.age}} | {{item.jobExperience?item.jobExperience+'年':'无'}} | {{item.educationMaxName}}</p>
      </div>
      <div class="bottom">
        <div class="btn">沟通</div>
        <h6>意向：</h6>
        <p>{{item.expectedPositionName}}</p>
      </div>
      <img src="../assets/img/recruit/icon-d.png" class="tag" alt="">
    </div>
  </div>
</template>
<script>
export default {
  name: "Resume",
  props: {
    dataList:{
      type: Array
    },
    ckzt: {
      type: Boolean
    }
  },
};
</script>
<style scoped lang="scss">
.item{
  width: 386px;
  height: 180px;
  background: #FFFFFF;
  border-radius: 0;
  opacity: 1;
  float: left;
  overflow: hidden;
  text-align: left;
  padding: 16px;
  box-sizing: border-box;
  margin: 0 20px 20px 0;
  position: relative;
  .tag{
    position: absolute;
    right: 24px;
    top: 20px;
    width: 16px;
    height: 19px;
    border-radius: 0;
  }
  img{
    width: 62px;
    height: 62px;
    border-radius: 50%;
    float: left;
  }
  .text{
    overflow: hidden;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    padding-left: 16px;
    padding-top: 10px;
    height: 62px;
    p{
      font-size: 14px;
      font-weight: 400;
      color: #999999;
    }
  }
  .bottom{
    margin-top: 16px;
    padding-top: 16px;
    clear: both;
    border-top: 1px solid #eee;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    h6{
      font-size: 14px;
      color: #666666;
      font-weight: normal;  
      margin-bottom: 3px;
      overflow: hidden;
    }
    p{
      color: #999999;
      overflow: hidden;
    }
    .btn{
      width: 76px;
      height: 32px;
      background: linear-gradient(171deg, #7CC3FF 0%, #3580DF 100%);
      border-radius: 16px 16px 16px 16px;
      opacity: 1;
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
      line-height: 32px;
      cursor: pointer;
      float: right;
      margin-top: 14px;
      &:hover{
        opacity: .8;
      }
    }
  }
}
</style>
