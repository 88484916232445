<template>
  <div class="home">
    <Head recruit="true"></Head>
    <div class="main">
      <div class="centerHead" v-if="userInfo">
        <div class="content">
          <div class="logo" v-if="userInfo.loginDO">
            <img :src="userInfo.avatar || '/mrtx.png'" alt="" />
            <div class="text">
              {{ userInfo.loginDO.account }} <br />
              {{ userInfo.userName }}
            </div>
          </div>
          <div class="data">
            <div class="item" @click="$router.push('/recruitCenter/releaseed')">
              {{ showCount.releaseCount }}
              <p>发布</p>
            </div>
            <div
              class="item"
              @click="$router.push('/recruitCenter/collection')"
            >
              {{ showCount.collectionCount }}
              <p>收藏</p>
            </div>
            <div class="item" @click="$router.push('/recruitCenter/wallet')">
              <!-- 88.00 -->
              <!-- <p>余额</p> -->
              <div class="btn">明细</div>
            </div>
          </div>
        </div>
      </div>
      <div class="content center" style="min-height: 500px; padding: 15px 0">
        <Resume
          style="margin-right: -20px"
          :dataList="collectionList"
          :ckzt="!!$route.query.rwzt"
        />
        <el-footer class="listPage" v-if="total > 0">
          <el-pagination
            :total="total"
            :current-page="search.pageNum"
            :page-size="search.pageSize"
            layout="total, prev, pager, next, jumper"
            @size-change="sizeChange"
            @current-change="currentChange"
          ></el-pagination>
        </el-footer>
        <el-empty
          style="padding: 60px 0 300px"
          v-if="!total"
          :image-size="100"
        ></el-empty>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import event from "@/utils/event";
import { count } from "@/api/zp";
import Head from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Resume from "@/components/Resume";
import { resumeList, queryGwck } from "@/api/zp";
export default {
  name: "Center",
  data() {
    return {
      search: {
        pageNum: 1,
        pageSize: 9,
      },
      total: 0,
      collectionList: [],
      userInfo: {},
      showCount: {},
    };
  },
  created() {
    this.loadCount();
    if (this.$route.query.rwzt) {
      this.getcollection();
    } else {
      this.queryGwck();
    }
  },
  mounted() {
    try {
      this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    } catch (error) {
      this.userInfo = {};
    }
    event.$on("loadCount", this.loadCount);
  },
  components: {
    Head,
    Footer,
    Resume,
  },
  methods: {
    loadCount() {
      count().then((res) => {
        this.showCount = res.data;
      });
    },
    // 获取投递记录
    getcollection() {
      resumeList({
        ...this.search,
        id: this.$route.query.id,
      }).then((res) => {
        this.collectionList = res.rows;
        this.total = res.total;
      });
    },
    // 获取查看岗位
    queryGwck() {
      queryGwck({
        pageSize: this.search.pageSize,
        pageCount:this.search.pageNum,
        id: this.$route.query.id,
      }).then((res) => {
        this.collectionList = res.rows;
        this.total = res.total;
      });
    },
    sizeChange(size) {
      this.search.pageNum = 1;
      this.search.pageSize = size;
      if (this.$route.query.rwzt) {
        this.getcollection();
      } else {
        this.queryGwck();
      }
    },
    currentChange(num) {
      this.search.pageNum = num;
      if (this.$route.query.rwzt) {
        this.getcollection();
      } else {
        this.queryGwck();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.centerHead {
  background: #edf5ff;
  padding: 32px 0;
  overflow: hidden;

  .logo {
    float: left;
    font-size: 28px;
    font-weight: bold;
    color: #333333;
    line-height: 118px;
    display: flex;
    img {
      width: 118px;
      height: 118px;
      vertical-align: middle;
      margin-right: 18px;
      border-radius: 5px;
    }
    .text {
      flex: 1;
      overflow: hidden;
      line-height: 40px;
      padding: 20px 0 0;
    }
  }

  .data {
    float: right;

    .item {
      margin-top: 22px;
      width: 117px;
      float: left;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      color: #333333;
      cursor: pointer;

      &:nth-child(2) {
        border-left: 1px solid #c3dafc;
        // border-right: 1px solid #C3DAFC;
      }

      p {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        margin-top: 6px;
      }

      .btn {
        display: inline-block;
        border-radius: 13px 13px 13px 13px;
        opacity: 1;
        border: 1px solid #0780e7;
        font-size: 14px;
        font-weight: 400;
        color: #0780e6;
        margin-top: 10px;
        width: 60px;
      }
    }
  }
}

.center {
  overflow: hidden;

  .left {
    float: left;
    width: 200px;
    margin: 20px 0;

    li {
      border-bottom: 1px solid #eeeeee;

      &:last-child {
        border: 0;
      }

      a {
        display: block;
        line-height: 98px;
        text-align: center;
        font-size: 18px;
        font-weight: normal;
        background: #fff;
        color: #333333;

        &.atthis {
          color: #0780e6;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 6px;
            height: 98px;
            background: linear-gradient(
              180deg,
              rgba(124, 195, 255, 0) 0%,
              #3580df 100%
            );
          }
        }
      }
    }
  }

  .right {
    margin-left: 220px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    overflow: hidden;
  }
}

.clear {
  clear: both;
}
</style>
